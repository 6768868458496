<template>
  <div class="position-relative">
    <MomoNav>
      <div class="nav-item">
        <router-link :to="{ name: 'LiffHuggiesMomoRedeem' }">
          <img class="" src="./images/momo_button_redeem.png" />
        </router-link>
      </div>
      <div class="nav-item">
        <a href="#">
          <img class="" src="./images/momo_button_redeem_record_active.png" />
        </a>
      </div>
      <div class="nav-item">
        <router-link :to="{ name: 'LiffHuggiesMomoPointRecord' }">
          <img class="" src="./images/momo_button_point_record.png" />
        </router-link>
      </div>
    </MomoNav>
    <div>
      <div>
        <router-link :to="{ name: 'LiffHuggiesMomoMemberIndex' }">
          <img class="home" src="./images/momo_profile_back_btn.png" />
        </router-link>
        <img
          class="layoutMomo-title"
          src="./images/momo_title_redeem_record.png"
        />
      </div>
      <div class="list mt-3">
        <MomoProductItem
          v-for="pointLog in pointLogs"
          :product="pointLog"
          :key="pointLog.id"
          :showDate="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MomoNav from "./MomoNav";
import MomoProductItem from "./MomoProductItem";
import Huggies from "@/apis/liff/Huggies";
import withFetcher from "@/mixins/liff/withFetcher";
import moment from "moment";

export default {
  mixins: [withFetcher],
  components: {
    MomoNav,
    MomoProductItem,
  },
  data() {
    return {
      pointLogs: [],
    };
  },
  methods: {
    async fetch() {
      await this.fetchRedeemLogs();
    },
    async fetchRedeemLogs() {
      let point_logs = await Huggies.getInstance().listRedeemLogs();
      if (!point_logs || !point_logs.length) {
        this.pointLogs = [];
        return;
      }

      this.pointLogs = point_logs.map((pl) => ({
        id: pl.id,
        title: pl.gift?.name,
        secondTitle: pl.gift?.data?.sub_title || "",
        point: pl.gift?.point_need,
        date: moment(pl.created_at).format("YYYY/MM/DD"),
        image: pl.gift?.image_url,
        status: "redeemed",
      }));
    },
  },
};
</script>

<style lang="scss">
.list {
  margin-bottom: 70px;
  > div {
    margin-bottom: 10px;
  }
}
</style>
